<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="60"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <slot name="text-title" />
      </p>
    </header>
    <section class="modal-card-body">
      <div slot="text-content">
        <pnet-submit-quote-update v-model="submitQuoteUpdateObject"
          :quote="value"
          :vehicle="vehicle"
          :customer="customer" />
      </div>
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-primary tooltip"
        @click="submit()"
        data-tooltip="Submit Quote Update"
        :disabled="isSubmitDisabled">
        <span class="icon is-small">
          <i class="mdi mdi-18px mdi-send" />
        </span>
        <span>Submit</span>
      </button>
      <button class="button tooltip"
        @click="cancel()"
        data-tooltip="Cancel">Cancel</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import PnetSubmitQuoteUpdate from './PnetSubmitQuoteUpdate'
import { PnetSubmitQuoteUpdateModel } from '@/classes'
import { PnetMessageService } from '../services'
import { EventHubTypes, PnetRepairStatus } from '@/enums'
import { QuoteNotificationMixin } from '../mixins'

export default {
  name: 'PnetSubmitQuoteUpdateModal',
  components: {
    BaseModalEx,
    PnetSubmitQuoteUpdate
  },
  mixins: [QuoteNotificationMixin],
  props: {
    value: null,
    vehicle: null,
    customer: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-file-send'
    }
  },
  data() {
    return {
      isActive: false,
      submitQuoteUpdateObject: null
    }
  },
  computed: {
    isSubmitDisabled() {
      let disabled = !this.submitQuoteUpdateObject.description
      if (this.submitQuoteUpdateObject.repairStatus === this.getPropertyName(PnetRepairStatus, s => s.NECD)) {
        disabled = disabled || !this.submitQuoteUpdateObject.jobEnd
      }
      return disabled
    }
  },
  watch: {},
  created() {
    this.submitQuoteUpdateObject = new PnetSubmitQuoteUpdateModel()
    this.submitQuoteUpdateObject.quoteId = this.value.quoteId
    this.submitQuoteUpdateObject.jobEnd = this.value.jobEnd
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    async submit() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('close')
      this.$showSpinner('Submitting Repair Rtatus')
      const result = await PnetMessageService.submitQuoteUpdate(this.submitQuoteUpdateObject)
      console.log(result)
      this.$hideSpinner()
      this.$eventHub.$emit(EventHubTypes.EntityReload)
      this.showSuccessToast('Repair Status is being submitted')
    },
    cancel() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('close')
    },
    getPropertyName(obj, expression) {
      let res = {}
      Object.keys(obj).map(k => {
        res[k] = () => k
      })
      return expression(res)()
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
