<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent is-vertical">
      <article class="tile is-child box detail-page-tile">
        <div class="field-body">
          <div class="field">
            <label class="label">Quote No</label>
            <span>{{ quote.quoteNo }}</span>
          </div>
          <div class="field">
            <label class="label">Authorised Quote No.</label>
            <span>{{ quote.pnet.authorisedQuoteNo }}</span>
          </div>
        </div>
        <div class="is-divider" />
        <label class="label">Repair Status</label>
        <div v-for="(status, prop) in pnetRepairStatus"
          :key="prop"
          class="field">
          <input class="is-checkradio"
            :id="prop"
            type="radio"
            :name="prop"
            :value="prop"
            v-model="innerValue.repairStatus">
          <label :for="prop">{{ status }}</label>
          <div v-if="innerValue.repairStatus === getPropertyName(pnetRepairStatus, s => s.NECD) && prop === 'NECD'"
            class="field is-pulled-right">
            <div class="field-body">
              <div class="field">
                <v-date-picker v-model="selectedJobEnd"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field">
                      <div class="control has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="End Date"
                          :value="inputValue"
                          v-on="inputEvents"
                          readonly>
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
              <div class="field">
                <div class="select">
                  <select v-model="hour">
                    <option v-for="h in hours"
                      :key="h"
                      :value="h">{{ h }}</option>
                  </select>
                </div>
                <div class="select">
                  <select v-model="minute">
                    <option v-for="min in minutes"
                      :key="min"
                      :value="min">{{ min | pad(2) }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">Description</label>
          <textarea class="textarea"
            v-model="innerValue.description"
            rows="5" />
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import { PnetRepairStatus } from '@/enums'
import { NumberFiltersMixin } from '@/components/mixins/filters'

export default {
  name: 'PnetSubmitQuoteUpdate',
  components: {},
  mixins: [NumberFiltersMixin],
  props: {
    value: null,
    quote: null,
    vehicle: null,
    customer: null
  },
  data() {
    return {
      innerValue: null,
      time: null,
      hours: [],
      minutes: [0, 15, 30, 45],
      hour: 0,
      minute: 0,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      selectedJobEnd: null
    }
  },
  computed: {
    pnetRepairStatus() {
      return PnetRepairStatus
    },
    propertyName() {
      // eslint-disable-next-line no-useless-escape
      const propName = (f) => /\.([^\.;]+);?\s*\}$/.exec(f.toString())[1]
      const prop = propName(() => {
        PnetRepairStatus.TRAC
      })
      return prop
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    hour(newVal, oldVal) {
      this.selectedJobEnd.setHours(newVal)
      this.innerValue.jobEnd = this.selectedJobEnd.toISOString().split('.')[0] + 'Z'
    },
    minute(newVal, oldVal) {
      this.selectedJobEnd.setMinutes(newVal)
      this.innerValue.jobEnd = this.selectedJobEnd.toISOString().split('.')[0] + 'Z'
    },
    selectedJobEnd(newVal, oldVal) {
      this.selectedJobEnd.setHours(this.hour)
      this.selectedJobEnd.setMinutes(this.minute)
      this.innerValue.jobEnd = newVal.toISOString().split('.')[0] + 'Z'
    }
  },
  created() {
    this.innerValue = this.value
    if (this.innerValue.jobEnd) {
      this.selectedJobEnd = new Date(`${this.innerValue.jobEnd}`)
      this.selectedJobEnd.setHours(0, 0, 0, 0)
    }
    for (let i = 0; i <= 23; i++) {
      this.hours.push(i)
    }
    this.innerValue.repairStatus = this.getPropertyName(this.pnetRepairStatus, (s) => s.TRAC)
  },
  mounted() {},
  methods: {
    getPropertyName(obj, expression) {
      let res = {}
      Object.keys(obj).map((k) => {
        res[k] = () => k
      })
      return expression(res)()
    }
  }
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
</style>
