<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    has-modal-card>
    <header class="modal-card-head">
      <div class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>Submit Images</span>
      </div>
    </header>
    <section class="modal-card-body">
      <image-select v-model="selectedImages"
        :quote-id="quoteId"
        :is-audanet="true"
        :max-selected="maxImages"
        :subtitle="`Select ${maxImages} images to submit`" />
    </section>
    <footer class="modal-card-foot">
      <div :style="`width: 100%; display: flex; justify-content: space-between;`">
        <div style="align-self: flex-start;">
          <div class="field">
            <div class="control">
              <input class="is-checkradio is-primary"
                :class="{ 'has-background-color': isResetSent }"
                id="chk-reset-sent"
                name="chk-reset-sent"
                type="checkbox"
                v-model="isResetSent">
              <label for="chk-reset-sent">Reset Sent</label>
            </div>
          </div>
        </div>
        <div class="buttons"
          style="align-self: flex-end;">
          <button class="button is-success tooltip"
            @click="submit()"
            data-tooltip="Submit">
            <span class="icon is-small">
              <i class="mdi mdi-18px mdi-upload-multiple" />
            </span>
            <span>Submit</span>
          </button>
          <button class="button tooltip"
            @click="cancel()"
            data-tooltip="Cancel"
            :disabled="cancelDisabled">
            <span>Cancel</span>
          </button>
        </div>
      </div>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import ImageSelect from '@/components/ImageSelect'
import { AudaNetService } from '../services'
import { EventHubTypes } from '@/enums'

export default {
  name: 'AudabridgeSubmitImagesModal',
  components: {
    BaseModalEx,
    ImageSelect
  },
  props: {
    value: null,
    quoteId: '',
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-upload-multiple'
    },
    copyDisabled: {
      type: Boolean,
      default: false
    },
    cancelDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      selectedImages: [],
      isResetSent: false,
      maxImages: 25
    }
  },
  validations: {},
  computed: {},
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  created() {
    this.innerValue = this.value
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    submit() {
      this.submitImages()
      // this.$eventHub.$emit(EventHubTypes.EntityReload)
      this.$emit('cancel')
    },
    cancel() {
      this.$emit('cancel')
    },
    async submitImages() {
      this.$showSpinner('Submitting images')
      const selectedIds = this.selectedImages.map(i => i.quoteImageId)
      const result = await AudaNetService.submitMultipleImages(this.value.quoteId, selectedIds, this.isResetSent)
      console.log(result)
      this.$hideSpinner()
      this.$eventHub.$emit(EventHubTypes.EntityReload)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
