<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="60"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <slot name="text-title" />
      </p>
    </header>
    <section class="modal-card-body">
      <div slot="text-content">
        <pnet-extra-vehicle-information v-if="value"
          v-model="submitQuoteObject.extraVehicleInformation"
          :quote="value"
          :vehicle="vehicle"
          :customer="customer"
          v-show="isExtraVehicleInfoActive" />
        <image-select v-if="value"
          v-show="isImageAttachActive"
          v-model="selectedImageIds"
          :quote-id="value.quoteId"
          :max-selected="maxImages"
          :subtitle="`Select ${maxImages} images to submit`" />
      </div>
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button v-show="value.pnet.extraVehicleInfo && isImageAttachActive === true"
        class="button is-success tooltip"
        @click="previous()"
        data-tooltip="Previous">
        <span class="icon is-small">
          <i class="mdi mdi-24px mdi-chevron-left" />
        </span>
      </button>
      <button v-show="isExtraVehicleInfoActive === true"
        class="button is-success tooltip"
        @click="next()"
        data-tooltip="Next">
        <span class="icon is-small">
          <i class="mdi mdi-24px mdi-chevron-right" />
        </span>
      </button>
      <button v-show="isImageAttachActive === true"
        class="button is-primary tooltip"
        @click="submit()"
        data-tooltip="Submit Quote"
        :disabled="selectedImageCount > maxImages">
        <span class="icon is-small">
          <i class="mdi mdi-18px mdi-send" />
        </span>
        <span>Submit</span>
      </button>
      <button class="button tooltip"
        @click="cancel()"
        data-tooltip="Cancel">Cancel</button>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import ImageSelect from '@/components/ImageSelect'
import PnetExtraVehicleInformation from './PnetExtraVehicleInformation'
import Guid from '@/components/Guid'
import { PnetSubmitQuoteModel } from '@/classes'
import { PnetMessageService } from '../services'
import { EventHubTypes } from '@/enums'
import { QuoteNotificationMixin } from '../mixins'

export default {
  name: 'PnetSubmitQuoteModal',
  components: {
    BaseModalEx,
    ImageSelect,
    PnetExtraVehicleInformation
  },
  mixins: [QuoteNotificationMixin],
  props: {
    value: null,
    vehicle: null,
    customer: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-file-send'
    }
  },
  data() {
    return {
      isActive: false,
      isImageAttachActive: false,
      isExtraVehicleInfoActive: false,
      selectedImageIds: [],
      extraVehicleInfo: {
        vin: this.vehicle.vin,
        transmissionType: this.vehicle.transmissionType,
        cylinders: this.vehicle.cylinders,
        buildMonth: this.vehicle.buildMonth,
        buildYear: this.vehicle.buildYear,
        odometer: this.vehicle.odometer,
        fuelLevel: 0,
        colour: this.vehicle.colour.toPascalCase(),
        damageDescription: '',
        oldDamageDescription: ''
      },
      submitQuoteObject: null
    }
  },
  computed: {
    maxImages() {
      return 25
    },
    selectedImageCount() {
      const count = this.selectedImageIds.filter(i => i !== Guid.empty()).length
      return count
    }
  },
  watch: {
    selectedImageIds: {
      handler: function(newVal, oldVal) {
        this.submitQuoteObject.imageIds = this.selectedImageIds.map(i => i.quoteImageId)
      },
      deep: true
    }
  },
  created() {
    this.submitQuoteObject = new PnetSubmitQuoteModel()
    this.submitQuoteObject.quoteId = this.value.quoteId
    this.submitQuoteObject.extraVehicleInformation.make = this.vehicle.model.make
    this.submitQuoteObject.extraVehicleInformation.bodyShape = ''
    this.submitQuoteObject.extraVehicleInformation.paintType = ''
    this.submitQuoteObject.extraVehicleInformation.vinChassis = this.vehicle.vin
    this.submitQuoteObject.extraVehicleInformation.transmissionType = this.vehicle.transmissionType
    this.submitQuoteObject.extraVehicleInformation.numberOfCylinders = this.vehicle.cylinders ? this.vehicle.cylinders : 0
    this.submitQuoteObject.extraVehicleInformation.monthOfManufacture = this.vehicle.buildMonth
    this.submitQuoteObject.extraVehicleInformation.yearOfManufacture = this.vehicle.buildYear
    this.submitQuoteObject.extraVehicleInformation.odometer = this.vehicle.odometer
    this.submitQuoteObject.extraVehicleInformation.fuelLevel = 0
    this.submitQuoteObject.extraVehicleInformation.color = this.vehicle.colour.toPascalCase()
    this.submitQuoteObject.extraVehicleInformation.damageDescription = ''
    this.submitQuoteObject.extraVehicleInformation.oldDamageDescription = ''
    if (this.value.pnet.extraVehicleInfo) {
      // show extra vehicle component
      this.isExtraVehicleInfoActive = true
    } else {
      this.isImageAttachActive = true
    }
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    previous() {
      this.isExtraVehicleInfoActive = true
      this.isImageAttachActive = false
    },
    next() {
      this.$emit('next')
      this.isExtraVehicleInfoActive = false
      this.isImageAttachActive = true
    },
    async submit() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('close')
      this.$showSpinner('Submitting quote')
      let result = ''
      if (this.value.subQuoteNo === 0) {
        result = await PnetMessageService.submitQuote(this.submitQuoteObject)
      } else {
        result = await PnetMessageService.submitSupplementaryQuote(this.submitQuoteObject)
      }
      console.log(result)
      this.$hideSpinner()
      this.$eventHub.$emit(EventHubTypes.EntityReload)
      this.showSuccessToast('Quote is being submitted')
    },
    cancel() {
      this.isActive = false
      this.$emit('update:active', false)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
