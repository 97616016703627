<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent is-vertical">
      <article class="tile is-child box detail-page-tile">
        <h1 class="subtitle">Extra Vehicle Information</h1>
        <div class="field-body">
          <div class="field">
            <label class="label">Quote No</label>
            <span>{{ quote.quoteNo }}</span>
          </div>
          <div class="field">
            <label class="label">Customer</label>
            <span>{{ customer.fullname }}</span>
          </div>
          <div class="field">
            <label class="label">Rego</label>
            <span>{{ vehicle.rego }}</span>
          </div>
          <div class="field">
            <label class="label">Model</label>
            <span>{{ vehicle.model.make }} {{ vehicle.model.model }}</span>
          </div>
        </div>
        <!-- </article> -->
        <div class="is-divider" />
        <!-- <article class="tile is-child box detail-page-tile"> -->
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Transmission</label>
              <div class="select">
                <select v-model="innerValue.transmissionType">
                  <option value="A">Automatic</option>
                  <option value="M">Manual</option>
                </select>
              </div>
            </div>
            <div class="field">
              <label class="label">VIN</label>
              <input type="text"
                class="input"
                v-model="innerValue.vinChassis">
            </div>
            <div class="field">
              <label class="label">Odometer</label>
              <vue-numeric class="input"
                v-model.number="innerValue.odometer"
                :precision="0"
                separator="" />
            </div>
            <div class="field">
              <label class="label">Build Month</label>
              <div class="select">
                <select v-model="innerValue.monthOfManufacture">
                  <option v-for="(m, key) in months"
                    :value="key"
                    :key="key">{{ m }}</option>
                </select>
              </div>
            </div>
            <div class="field">
              <label class="label">Damage Description</label>
              <textarea class="textarea"
                v-model="innerValue.damageDescription"
                name=""
                rows="3" />
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label">Cylinders</label>
              <vue-numeric
                class="input"
                v-model.number="innerValue.numberOfCylinders"
                :precision="0"
                separator="" />
            </div>
            <div class="field">
              <label class="label">Fuel Level</label>
              <vue-numeric
                class="input"
                v-model.number="innerValue.fuelLevel"
                :precision="0"
                :max="100"
                symbol="%"
                symbol-position="suffix"
                separator="" />
            </div>
            <div class="field">
              <label class="label">Colour</label>
              <div class="select">
                <select v-model="innerValue.color">
                  <option
                    v-for="(colour, prop) in pnetColourCodeTypes"
                    :key="prop"
                    :value="colour">{{ colour }}</option>
                </select>
              </div>
            </div>
            <div class="field">
              <label class="label">Build Year</label>
              <!-- <vue-numeric class="input"
                v-model.number="innerValue.buildYear"
                :precision="0"
                separator=""
                maxlength="4"
                @blur.native="checkYear"  /> -->
              <the-mask
                class="input"
                placeholder="e.g. 2000"
                v-model="innerValue.yearOfManufacture"
                :mask="['####']"
                @blur.native="checkYear" />
            </div>
            <div class="field">
              <label class="label">Old Damage Description</label>
              <textarea
                class="textarea"
                v-model="innerValue.oldDamageDescription"
                name=""
                rows="3" />
            </div>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import VueNumeric from '@/components/VueNumeric'
import { PnetColourCodeTypes } from '@/enums'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'PnetExtraVehicleInformation',
  components: {
    VueNumeric,
    TheMask
  },
  props: {
    value: null,
    quote: null,
    vehicle: null,
    customer: null
  },
  data() {
    return {
      innerValue: null,
      months: {
        1: '01',
        2: '02',
        3: '03',
        4: '04',
        5: '05',
        6: '06',
        7: '07',
        8: '08',
        9: '09',
        10: '10',
        11: '11',
        12: '12'
      }
    }
  },
  computed: {
    pnetColourCodeTypes() {
      return PnetColourCodeTypes
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.innerValue = this.value
  },
  mounted() {},
  methods: {
    checkYear(e) {
      const year = e.target.value
      if (year.length < 4) {
        const strYear = `01/01/${year}`
        const newDate = new Date(strYear)
        this.innerValue.buildYear = newDate.getFullYear()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
</style>
